import React, { useEffect, useRef, useState } from "react";
import { iconNotification } from "../../constants/images";
import { Badge } from "@mui/material";
import "./notification.css";
import { useNavigate } from "react-router-dom";
import { BASPATH } from "../../config/appConfig";
import {
  getAllNotification,
  notificationMarkRead,
} from "../../services/notification.service";
import moment from "moment";

const Notification = () => {
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const [toggleNotification, setToggleNotification] = useState(false);
  const [notificationListData, setNotificationListData] = useState({});
  const [notificationCount, setNotificationCount] = useState(0);

  const params = {
    currentPage: 1,
    perPage: 5,
    sortColumn: "createdAt",
    sort: "desc",
  };

  useEffect(() => {
    getNotificationList(params);

    const intervalCall = setInterval(() => {
      getNotificationList(params);
    }, 300000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  const getNotificationList = async (param) => {
    const response = await getAllNotification(param);

    if (response && response.status === 200 && response.data.data) {
      setNotificationListData(response.data.data);
      setNotificationCount(response.data.data.unSeenCount);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleNotificationClickOutside, true);
    return () => {
      document.removeEventListener(
        "click",
        handleNotificationClickOutside,
        true
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNotificationClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setToggleNotification(false);
    }
  };

  const markRead = async () => {
    try {
      const response = await notificationMarkRead();
      if (response && response.status === 200) {
        setNotificationCount(0);
      }
    } catch (error) {}
  };

  return (
    <>
      <div ref={notificationRef}>
        <div
          className="notification-wrapper cursor-pointer"
          style={{ position: "relative" }}
          onClick={() => setToggleNotification(!toggleNotification)}
        >
          <Badge
            sx={{
              "& .MuiBadge-badge": { fontSize: 12, height: 18, minWidth: 18 },
            }}
            overlap="circular"
            badgeContent={notificationCount}
            color="error"
          >
            <img src={iconNotification} width={24} height={24} alt="menu" />
          </Badge>
        </div>

        {toggleNotification && (
          <ul className={"notificationBar"}>
            <div className="notification-header">
              <h5 style={{ fontWeight: 800 }}>Notifications</h5>
              {notificationCount > 0 && (
                <p
                  className="mark-all-read cursor-pointer"
                  onClick={() => {
                    markRead();
                  }}
                >
                  Mark all as read
                </p>
              )}
            </div>

            {notificationListData.dataList &&
              notificationListData.dataList.map((items, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      background: "#fff",
                      padding: "5px",
                      marginBottom: "10px",
                    }}
                    className={"lineItmes"}
                  >
                    <p
                      style={{ fontWeight: 700, marginBottom: "5px" }}
                    >{`${items.title}`}</p>

                    <p style={{ marginBottom: "5px" }}>{items.msg}</p>

                    <span
                      style={{
                        fontSize: "12px",
                        float: "right",
                      }}
                    >
                      {items.createdAt
                        ? moment(items.createdAt).format("yyyy-MM-DD, hh:mm A")
                        : ""}
                    </span>
                  </div>
                );
              })}

            <p
              className="view-all cursor-pointer"
              onClick={() => {
                setToggleNotification(!toggleNotification);
                navigate(BASPATH + "notifications");
              }}
            >
              See All
            </p>
          </ul>
        )}
      </div>
    </>
  );
};

export default Notification;
