//import { Dimensions } from "react-native";
//import Toast from 'react-native-simple-toast';

import { toast } from "react-hot-toast";

const cond1 = "/^(?=.*[a-z]).{6,20}$/";
const cond2 = "/^(?=.*[A-Z]).{6,20}$/";
const cond3 = "/^(?=.*[0-9]).{6,20}$/";

// eslint-disable-next-line
export const isEmailValid = (email) => {
  // eslint-disable-line
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePassword = (password) => {
  if (!password) {
    return "Password is required";
  } else if (password.length < 6) {
    return "Password must be longer than 6 characters";
  } else if (password.length >= 20) {
    return "Password must shorter than 20 characters";
  } else if (!password.match(cond1)) {
    return "Password must contain at least one lowercase";
  } else if (!password.match(cond2)) {
    return "Password must contain at least one capital letter";
  } else if (!password.match(cond3)) {
    return "Password must contain at least a number";
  } else {
    return "";
  }
};

export const urlPatternValidation = (URL) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  return regex.test(URL);
};

export function drawImageScaled(imgWidth, imgHeight) {
  //  const { innerWidth: width, innerHeight: height } = window;

  //let screenSize = Dimensions.get('window');
  let hRatio = (window.width - 40) / imgWidth;
  let vRatio = window.height / imgHeight;
  let ratio = Math.min(hRatio, vRatio);
  return {
    width: parseInt(imgWidth * ratio),
    height: parseInt(imgHeight * ratio),
  };
}

export const ShowErrorToast = (error) => {
  // if (error.data)
  //     toast(error.data && error.data.message)
  if (
    error &&
    error.data &&
    error.data.errors &&
    error.data.errors.length > 0
  ) {
    const obj = error.data.errors.map(
      (item, index) => (index ? ", " : "") + Object.values(item)
    );
    toast.error(obj);
  } else {
    if (error && error.data) toast(error.data && error.data.message);
  }
};

export const ShowToast = (msg) => {
  if (msg) toast.success(msg);
};

