import React, { useContext, createContext, useState, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { getUserProfile } from "../services/user.service";

const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  const [AuthUser] = useLocalStorage("auth", {});
  const [userPermission, setUserPermission] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  function getUser(user) {
    setUserPermission(user.profile ? user.profile.permissions : user);
    setUserRole(user.profile ? user.profile.role.name : user);
    setUserProfile(user.profile ? user.profile : user);
  }
  // if (userPermission && userPermission.profile) {
  // setUserPermission(userPermission.profile.permissions);
  // setUserRole(userPermission.profile.role.name);
  // }
  const getUserProfiles = async () => {
    const response = await getUserProfile();
    if (response && response.status === 200 && response.data.data) {
      setUserPermission(response.data.data.profile.permissions);
      setUserRole(response.data.data.profile.role.name);
      setUserProfile(response.data.data.profile);
    }
  };

  useEffect(() => {
    if (AuthUser.profile) getUserProfiles();
  }, []);

  return (
    <PermissionContext.Provider
      value={{
        userPermission: userPermission,
        getUser: getUser,
        userRole: userRole,
        userProfile: userProfile,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = () => useContext(PermissionContext);

export default PermissionContext;
