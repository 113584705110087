import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import { CssBaseline } from "@mui/material";
import { iconMenu } from "../../constants/images";
import UserHeader from "./userHeader";
import SideMenu from "./sideMenu";

export const drawerWidth = 300;

const AppBarUser = (props) => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };
  // const NotificationComponent = () => {
  //   return (
  //     <div>

  //     </div>
  //   )
  // };

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const dynamicWidth =
    screenSize.dynamicWidth >= 1400
      ? drawerWidth
      : screenSize.dynamicWidth >= 1200
      ? 300
      : screenSize.dynamicWidth >= 992
      ? 250
      : screenSize.dynamicWidth >= 768
      ? 180
      : screenSize.dynamicWidth >= 596
      ? 180
      : 160;

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <CssBaseline />

      <SideMenu
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        dynamicWidth={dynamicWidth}
      />

      <AppBar
        position="fixed"
        color="primary"
        sx={{
          width: { sm: `calc(100% - ${dynamicWidth}px)` },
          ml: { sm: `${dynamicWidth}px` },
        }}
      >
        <Toolbar sx={{ mr: 2, display: { sm: "none" } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            style={{ marginLeft: 10 }}
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <img src={iconMenu} width={20} height={20} alt="menu" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <UserHeader dynamicWidth={dynamicWidth} />
    </Box>
  );
};
export default AppBarUser;
