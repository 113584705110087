//import AsyncStorage from '@react-native-async-storage/async-storage';
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { applyMiddleware, compose, createStore } from "redux";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = applyMiddleware(thunk);

export const store = createStore(
  persistedReducer,
  composeEnhancers(middleware)
);

export let persistor = persistStore(store);

// export const store = createStore(persistedReducer)
// export const persistor = persistStore(store)

// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// import rootReducer from './reducers'
