const user = require("../assets/images/user.png");
const logo = require("../assets/images/logo.png");
const logoLight = require("../assets/images/logo-dark.png");
const eyeView = require("../assets/images/eye_view.png");
const eyeHide = require("../assets/images/eye_hide.png");
const iconPassword = require("../assets/images/icon_password.png");
const iconUser = require("../assets/images/icon_user.png");
const iconUserLight = require("../assets/images/icon_user-light.png");
const iconMenu = require("../assets/images/menu.png");
const iconDashboard = require("../assets/images/dashboard.png");
const iconMessage = require("../assets/images/message.png");
const iconReport = require("../assets/images/report.png");
const iconTelentBench = require("../assets/images/telent-bench.png");
const iconVendor = require("../assets/images/vendor.png");
const iconSearch = require("../assets/images/search.png");
const iconSearchDark = require("../assets/images/search-dark.png");
const iconNotification = require("../assets/images/notification.png");
const imgDeafultProfile = require("../assets/images/deafult-profile.png");
const imgViewDark = require("../assets/images/view-dark.png");
const iconArrowDown = require("../assets/images/arrow-down.png");
const iconPencil = require("../assets/images/pencil.png");
const iconBin = require("../assets/images/bin.png");
const iconUpload = require("../assets/images/upload.png");
const iconUploadProfile = require("../assets/images/upload.png"); // -upload-profile
const iconView = require("../assets/images/view.png");
const iconEdit = require("../assets/images/edit.png");
const iconDelete = require("../assets/images/delete.png");
const iconAdd = require("../assets/images/add.png");
const iconResume = require("../assets/images/resume.png");
const iconMailDark = require("../assets/images/mail-dark.png");
const iconSmartPhone = require("../assets/images/smartphone.png");
const iconLocation = require("../assets/images/location.png");
const iconAddDark = require("../assets/images/add-dark.png");
const iconShare = require("../assets/images/share.png");
const iconDownload = require("../assets/images/download.png");
const iconSubmit = require("../assets/images/submit.png");
const iconCalendar = require("../assets/images/calendar.png");
const iconInterview = require("../assets/images/interview.png");
const iconCheckMark = require("../assets/images/check-mark.png");
const iconSetting = require("../assets/images/setting.png");
const iconUpArrow = require("../assets/images/upArrow.png")
const iconDownArrow = require("../assets/images/downArrow.png")

export {
  user,
  logo,
  logoLight,
  eyeView,
  eyeHide,
  iconPassword,
  iconUser,
  iconUserLight,
  iconMenu,
  iconDashboard,
  iconMessage,
  iconReport,
  iconTelentBench,
  iconVendor,
  iconSearch,
  iconSearchDark,
  iconNotification,
  imgDeafultProfile,
  imgViewDark,
  iconArrowDown,
  iconPencil,
  iconBin,
  iconUpload,
  iconUploadProfile,
  iconView,
  iconEdit,
  iconDelete,
  iconAdd,
  iconResume,
  iconMailDark,
  iconSmartPhone,
  iconLocation,
  iconAddDark,
  iconShare,
  iconDownload,
  iconSubmit,
  iconCalendar,
  iconInterview,
  iconCheckMark,
  iconSetting,
  iconUpArrow,
  iconDownArrow
};
