import React from "react";
import "./App.css";
import "./theme/globalStyle.css";
import RouterComponent from "./router/RouterComponent";
import "react-datepicker/dist/react-datepicker.css";

import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Router>
      <RouterComponent />
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "green",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "white",
            },
          },
         
        }}
      />
    </Router>
  );
}

export default App;
