import axios from "axios";
import { API_URL } from "../../config";
import authService from "../../services/auth.service";

const LOGIN_REQUESTED = "LOGIN_REQUESTED";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILED = "LOGIN_FAILED";
const IS_LOGGED_IN = "IS_LOGGED_IN";
const LOGOUT = "LOGOUT";
const PROFILE_SUCCESS = "PROFILE_SUCCESS";
const PROFILE_FAILED = "PROFILE_FAILED";
const REGISTER_SUCCESS = "REGISTER_SUCCESS";
const VERIFY_SUCCESS = "VERIFY_SUCCESS";
const REGISTER_FAILED = "REGISTER_FAILED";
const NOTIFICATION_LIST = "NOTIFICATION_LIST";
const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
const GET_USER_BY_TOKEN_SUCCESS = "GET_USER_BY_TOKEN_SUCCESS";

export const singinActions = {
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  IS_LOGGED_IN,
  LOGOUT,
  PROFILE_SUCCESS,
  PROFILE_FAILED,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  VERIFY_SUCCESS,
  NOTIFICATION_LIST,
  FORGOT_PASSWORD_SUCCESS,
  GET_USER_BY_TOKEN_SUCCESS,
};

const actions = singinActions;

export const loginApi = (request) => (dispatch) => {
  dispatch({
    type: actions.LOGIN_REQUESTED,
  });
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {
      const data = response.data;
      if (data.status) {
        dispatch({
          type: actions.LOGIN_SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: actions.LOGIN_FAILED,
        });
      }
      resolve(data);
    };
    const onFailure = (error) => {
      dispatch({
        type: actions.LOGIN_FAILED,
      });
      reject(error.response);
    };
    authService
      .login(request)
      .then(onSuccess)
      .catch(onFailure);
  });
};

export const forgotApi = (request) => (dispatch) => {
  dispatch({
    type: actions.LOGIN_REQUESTED,
  });
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {
      dispatch({
        type: actions.FORGOT_PASSWORD_SUCCESS,
      });
      const data = response.data;
      resolve(data);
    };
    const onFailure = (error) => {
      dispatch({
        type: actions.FORGOT_PASSWORD_SUCCESS,
      });
      reject(error.response);
    };
    authService
      .forgotpassword(request)
      .then(onSuccess)
      .catch(onFailure);
  });
};

export const registerApi = (request) => (dispatch) => {
  dispatch({
    type: actions.LOGIN_REQUESTED,
  });
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {
      const data = response.data;
      if (data.success) {
        dispatch({
          type: actions.REGISTER_SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: actions.LOGIN_FAILED,
        });
      }
      resolve(data);
    };
    const onFailure = (error) => {
      reject(error.response);
      dispatch({
        type: actions.LOGIN_FAILED,
      });
    };
    axios
      .post(API_URL + "register", request, {
        headers: {
          accept: "application/json",
          "content-type": "multipart/form-data",
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  });
};

export const apiVerifyOtp = (request, token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {
      const data = response.data;
      if (data.success) {
      }
      resolve(data);
    };
    const onFailure = (error) => {
      reject(error.response);
    };

    axios
      .post(API_URL + "verify_phone_number", request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  });
};

export const apiResendOtp = (token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {
      const data = response.data;
      resolve(data);
    };
    const onFailure = (error) => {
      reject(error.response);
    };

    axios
      .get(API_URL + "resend_otp", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  });
};

export const logoutApi = (token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {
      const data = response.data;
      //if (data.success) {
      resolve(data);
      //}
    };
    const onFailure = (error) => {
      reject(error.response);
    };

    axios
      .get(API_URL + "logout", {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "application/json",
          "content-type": "multipart/form-data",
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  }); //http://15.207.152.121/smartapp/api/logout
};

export const deleteAccountApi = (token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {
      const data = response.data;
      if (data.success) {
        resolve(data);
      }
    };
    const onFailure = (error) => {
      reject(error.response);
    };

    axios
      .get(API_URL + "delete_account", {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "application/json",
          "content-type": "multipart/form-data",
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  }); 
};
