import { Box, Popover } from "@mui/material";
import React from "react";
import Notification from "../Notification/notification";
import UserHeaderProfile from "../UserHeaderProfile/UserHeaderProfile";
import { actions } from "../../redux/actions";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { BASPATH } from "../../config/appConfig";
import { UseAuth } from "../../contexts/authProvider";

const UserHeader = ({ dynamicWidth }) => {
  const { createAuthUser } = UseAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogout = () => {
    setAnchorEl(null);
    const options = {
      title: "Logout",
      message: "Do you want to logout?",
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            //  alert('Click Yes')
            setTimeout(() => {
              Logout();
            }, 0);
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            //alert('Click No')
          },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    };

    confirmAlert(options);
  };

  const Logout = () => {
    createAuthUser({});
    //localStorage.removeItem("auth");
    // localStorage.removeItem(config.authTokenKey);
    delete axios.defaults.headers.common["Authorization"];
    // clearCacheData();
    dispatch({
      type: actions.login.LOGOUT,
    });
    navigate(BASPATH + "login");

    // dispatch(logoutApi(authUser.token))
    //   .then((res) => {
    //     localStorage.removeItem("auth");
    //     // localStorage.removeItem(config.authTokenKey);
    //     delete axios.defaults.headers.common["Authorization"];
    //     // clearCacheData();
    //     dispatch({
    //       type: actions.login.LOGOUT
    //     });
    //     navigate(BASPATH + "auth/login");
    //   }, 150)
    //   .catch((error) => {
    //     ShowErrorToast(error)
    //   })
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const textStyle = {
    color: "#000",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    marginTop: "10px",
    //     color: #000;
    // font-family: Roboto;
    // font-size: 16px;
    // font-style: normal;
    // font-weight: 400;
    // line-height: normal;
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: `${dynamicWidth === 160 ? 0 : dynamicWidth}px`,
        width: "100%",
        padding: "20px",
      }}
    >
      {/* <SearchBar /> */}

      <div />

      <div className="d-flex">
        <Notification />
        <UserHeaderProfile handleClick={handleClick} id={id} />
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: "16px" }}>
          <span
            className="pointer"
            style={textStyle}
            onClick={() => navigate(BASPATH + "edit-profile")}
          >
            Edit Profile
          </span>
          <span
            className="pointer"
            style={textStyle}
            onClick={() => navigate(BASPATH + "change-password")}
          >
            Change Password
          </span>
          <span className="pointer" style={textStyle} onClick={onLogout}>
            Sign Out
          </span>
        </Box>
      </Popover>
    </Box>
  );
};

export default UserHeader;
