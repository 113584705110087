import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AuthsRoutes, RoutesPath } from "./routes/Routes";
import AuthLayOut from "../defaultLayout/AuthLayout";
import Layout from "../defaultLayout/Index";
import { Suspense } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import { UseAuth } from "../contexts/authProvider";
import NotFound from "../components/layout/NotFound";
import { usePermission } from "../contexts/permissionProvider";
import { BASPATH } from "../config/appConfig";

const RouterComponent = () => {
  const { AuthUser } = UseAuth();
  const { userPermission } = usePermission();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {}, [AuthUser]);

  useEffect(() => {
    if (
      location.pathname.includes("/forgot-password") ||
      location.pathname.includes("/reset-password") ||
      location.pathname.includes("talent-bench-edit")
    ) {
    } else {
      if (!AuthUser.token) {
        navigate(`${BASPATH}login`);
      }
    }
  }, [AuthUser, navigate]);

  return (
    <Routes>
      {/* {AuthUser.token && (
        <Route path="/" element={<Layout />}>
          {RoutesPath.map((route, id) => (
            <Route
              key={id}
              path={route.path}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <route.element />
                </Suspense>
              }
            />
          ))}
        </Route>
      )} */}

      {AuthUser.token && (
        <Route path="/" element={<Layout />}>
          {RoutesPath.map((route, id) => {
            return route && route.permission && userPermission ? (
              userPermission[route.permission] && (
                <Route
                  key={id}
                  path={route.path}
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <route.element />
                    </Suspense>
                  }
                />
              )
            ) : (
              <Route
                key={id}
                path={route.path}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <route.element />
                  </Suspense>
                }
              />
            );
          })}
        </Route>
      )}

      {!AuthUser.token && (
        <Route path="/" element={<AuthLayOut />}>
          {AuthsRoutes.map((route, id) => (
            <Route
              key={id}
              path={route.path}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <route.element />
                </Suspense>
              }
            />
          ))}
        </Route>
      )}

      <Route path="*" element={<AuthLayOut />}>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default RouterComponent;
