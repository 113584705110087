import { BASPATH } from "../../config/appConfig";
import {
  iconDashboard,
  iconMessage,
  iconReport,
  iconTelentBench,
  iconUserLight,
  iconVendor,
  iconInterview,
  iconSetting,
} from "../../constants/images";

export const sidebarRoutes = [
  {
    title: "Dashboard",
    path: BASPATH + "dashboard",
    icon: iconDashboard,
  },
  {
    title: "Users",
    path: BASPATH + "users",
    icon: iconUserLight,
    permission: "userView",
  },
  {
    title: "User Role",
    path: BASPATH + "user-role",
    icon: iconUserLight,
    permission: "roleView",
  },
  {
    title: "Vendors",
    path: BASPATH + "vendors",
    icon: iconVendor,
    permission: "vendorView",
  },
  {
    title: "Talent Bench",
    path: BASPATH + "talent-bench",
    icon: iconTelentBench,
    permission: "candidateView",
  },
  {
    title: "Talent Bench Hotlist",
    path: BASPATH + "talent-bench-hotlist",
    icon: iconTelentBench,
    permission: "candidateView",
  },
  {
    title: "Submission",
    path: BASPATH + "submission/list",
    icon: iconUserLight,
    permission: "submissionView",
  },
  {
    title: "Interview",
    path: BASPATH + "interview/list",
    icon: iconInterview,
    permission: "interviewView",
  },
  {
    title: "Messages",
    path: BASPATH + "smtp-setting",
    icon: iconMessage,
  },
  {
    title: "Hotlist",
    path: BASPATH + "hotlist",
    icon: iconMessage,
    permission: "hotlistCreate"
  },
  {
    title: "Reports",
    icon: iconReport,
    permission: "reportView",
    subNav: [
      {
        title: "Interview Report",
        path : BASPATH + "report/interview"
      },{
        title: "Submission Report",
        path : BASPATH + "report/submission"
      },
    ],
  },
  {
    title: "Settings",
    icon: iconSetting,
    permission: "settingView",
    subNav: [
      {
        title: "Talent Bench Status",
        path : BASPATH + "talent-bench-status"
      },
    ],
  },
];
