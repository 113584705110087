import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AppBarUser, { drawerWidth } from "../components/Header/appBar";
import { Box } from "@mui/material";

const Layout = () => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const dynamicWidth =
    screenSize.dynamicWidth >= 1400
      ? drawerWidth
      : screenSize.dynamicWidth >= 1200
      ? 300
      : screenSize.dynamicWidth >= 992
      ? 250
      : screenSize.dynamicWidth >= 768
      ? 180
      : 180;

  return (
    <div className="">
      <AppBarUser />
      <Box
        component="main"
        // style={{ height: "95vh" }}
        sx={{
          flex: 1,
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${dynamicWidth + 20}px)` },
          marginLeft: { sm: `${dynamicWidth + 20}px` },
          marginTop: { xl: "0px" },
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
};

export default Layout;
