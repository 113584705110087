/** @format */
import { lazy } from "react";

import { BASPATH } from "../../config/appConfig";

const Login = lazy(() => import("../../views/login/login"));
const Dashboard = lazy(() => import("../../views/dashboard/dashboard"));
const Users = lazy(() => import("../../views/users/Users"));
const Vendors = lazy(() => import("../../views/vendor/vendors/Vendor"));
const AddEditUser = lazy(() =>
  import("../../views/users/addEditUser/AddEditUser")
);
const UserDetail = lazy(() =>
  import("../../views/users/userDetail/UserDetail")
);

const AddUpdateVendor = lazy(() =>
  import("../../views/vendor/eddeditvendors/AddUpdateVendor")
);
const VendorDetail = lazy(() =>
  import("../../views/vendor/vendorDetail/VendorDetail")
);
const TalentBench = lazy(() => import("../../views/talentBench/talentBench"));
const TalentBenchHotlist = lazy(() => import("../../views/talentBench/TalentBenchHotlist"));
const AddEditTalentBench = lazy(() =>
  import("../../views/talentBench/addedittelentbench/AddEditTalentBench")
);
const EditTalentBench = lazy(() =>
  import("../../views/talentBench/addedittelentbench/EditTalentBench")
);
const TalentBenchDetails = lazy(() =>
  import("../../views/talentBench/talentBenchDetails/TalentBenchDetails")
);
const UserRole = lazy(() => import("../../views/userRole/UserRole"));
const AddUpdateUserRole = lazy(() =>
  import("../../views/userRole/AddUpdateUserRole")
);
const ForgotPassword = lazy(() =>
  import("../../views/auth/forgotPassword/forgotPassword")
);
const ResetPassword = lazy(() =>
  import("../../views/auth/resetPassword/resetPassword")
);
const Submission = lazy(() =>
  import("../../views/talentBench/submission/Submission")
);
const SubmissionList = lazy(() =>
  import("../../views/submission/SubmissionList")
);
const SubmissionListBySalesPerson = lazy(() =>
  import("../../views/submission/SubmissionListBySalesperson")
);
const SubmissionDetail = lazy(() =>
  import("../../views/submission/SubmissionDetail")
);
const EditProfile = lazy(() => import("../../views/auth/profile/EditProfile"));
const InterviewList = lazy(() => import("../../views/interview/InterviewList"));
const SmtpSetting = lazy(() => import("../../views/messages/SmtpSetting"));
const Hotlist = lazy(() => import("../../views/hotlist/Hotlist"));
const ChangePassword = lazy(() => import("../../views/auth/changePassword/ChangePassword"));
const NotificationList = lazy(() => import("../../views/notification/NotificationList"));
const TalentBenchStatus = lazy(() => import("../../views/settings/talentBenchStatus/TalentBenchStatus"));
const AddTalentBenchStatus = lazy(() => import("../../views/settings/talentBenchStatus/AddTalentBenchStatus"))
const ReportInterview = lazy(() => import("../../views/report/InterviewReport"))
const ReportSubmission = lazy(() => import("../../views/report/SubmissionReport"))


export const AuthsRoutes = [
  {
    path: BASPATH,
    name: "Login",
    element: Login,
  },
  {
    path: BASPATH + "login",
    name: "Login",
    element: Login,
  },
  {
    path: BASPATH + "forgot-password",
    name: "Forgot Password",
    element: ForgotPassword,
  },
  {
    path: BASPATH + "reset-password/:token",
    name: "Reset Password",
    element: ResetPassword,
  },
  {
    path: BASPATH + "talent-bench-edit/:itemId/*",
    name: "Talent Bench Edit",
    element: EditTalentBench,
  },
];

export const RoutesPath = [
  {
    path: BASPATH,
    name: "Dashboard",
    element: Dashboard,
  },
  {
    path: BASPATH + "dashboard",
    name: "Dashboard",
    element: Dashboard,
  },
  {
    path: BASPATH + "users",
    name: "Users",
    element: Users,
    permission: "userView",
  },
  {
    path: BASPATH + "users/role/:roleId",
    name: "User Role wise",
    element: Users,
    permission: "userView",
  },
  {
    path: BASPATH + "users/eddedit/:userId",
    name: "eddedit",
    element: AddEditUser,
    permission: "userEdit",
  },
  {
    path: BASPATH + "users/eddedit/",
    name: "eddedit",
    element: AddEditUser,
    permission: "userCreate",
  },
  {
    path: BASPATH + "users/detail/:userId",
    name: "detail",
    element: UserDetail,
    permission: "userView",
  },
  {
    path: BASPATH + "vendors",
    name: "Vendors",
    element: Vendors,
    permission: "vendorView",
  },
  {
    path: BASPATH + "vendors/detail/:itemId",
    name: "detail",
    element: VendorDetail,
    permission: "vendorView",
  },
  {
    path: BASPATH + "vendors/add",
    name: "Add Vendor",
    element: AddUpdateVendor,
    permission: "vendorCreate",
  },
  {
    path: BASPATH + "vendors/edit/:id",
    name: "Edit Vendor",
    element: AddUpdateVendor,
    permission: "vendorEdit",
  },
  {
    path: BASPATH + "talent-bench",
    name: "talent-bench",
    element: TalentBench,
    permission: "candidateView",
  },
  {
    path: BASPATH + "talent-bench-hotlist",
    name: "talent-hotlist",
    element: TalentBenchHotlist,
    permission: "candidateView",
  },
  {
    path: BASPATH + "talent-bench/edit/:itemId/*",
    name: "edit",
    element: AddEditTalentBench,
    permission: "candidateEdit",
  },
  {
    path: BASPATH + "talent-bench/add/*",
    name: "add",
    element: AddEditTalentBench,
    permission: "candidateCreate",
  },
  {
    path: BASPATH + "talent-bench-edit/:itemId/*",
    name: "Talent Bench Edit",
    element: EditTalentBench,
  },
  {
    path: BASPATH + "talent-bench/detail/:itemId",
    name: "detail",
    element: TalentBenchDetails,
  },
  {
    path: BASPATH + "talent-bench/submission/:id",
    name: "Talent Submission",
    element: Submission,
    permission: "submissionCreate",
  },
  {
    path: BASPATH + "submission/list",
    name: "Submission List",
    element: SubmissionList,
    permission: "submissionView",
  },
  {
    path: BASPATH + "submission/list/:id",
    name: "Submission List",
    element: SubmissionListBySalesPerson,
    permission: "submissionView",
  },
  {
    path: BASPATH + "submission/detail/:id",
    name: "detail",
    element: SubmissionDetail,
  },
  {
    path: BASPATH + "interview/list",
    name: "interview List",
    element: InterviewList,
    permission: "interviewView",
  },
  {
    path: BASPATH + "user-role",
    name: "user-role",
    element: UserRole,
    permission: "roleView",
  },
  {
    path: BASPATH + "user-role/add",
    name: "Add User Role",
    element: AddUpdateUserRole,
    permission: "roleCreate",
  },
  {
    path: BASPATH + "user-role/edit/:id",
    name: "Edit User Role",
    element: AddUpdateUserRole,
    permission: "roleEdit",
  },
  {
    path: BASPATH + "edit-profile",
    name: "Edit Profile",
    element: EditProfile,
  },
  {
    path: BASPATH + "smtp-setting",
    name: "SMTP Settings",
    element: SmtpSetting,
  },
  {
    path: BASPATH + "hotlist",
    name: "Hotlist",
    element: Hotlist,
    permission: "hotlistCreate"
  },
  {
    path: BASPATH + "change-password",
    name: "Change Password",
    element: ChangePassword,
  },
  {
    path: BASPATH + "notifications",
    name: "Notification List",
    element: NotificationList,
  },
  {
    path: BASPATH + "talent-bench-status",
    name: "Talent Bench Status",
    element: TalentBenchStatus,
    permission: "settingView"
  },
  {
    path: BASPATH + "talent-bench-status/add",
    name: "Add Talent Bench Status",
    element: AddTalentBenchStatus,
    permission: "settingCreate"
  },
  {
    path: BASPATH + "talent-bench-status/edit/:id",
    name: "Edit Talent Bench Status",
    element: AddTalentBenchStatus,
    permission: "settingEdit"
  },
  {
    path: BASPATH + "report/interview",
    name: "Interview Report",
    element: ReportInterview,
    permission: "reportView"
  },
  {
    path: BASPATH + "report/submission",
    name: "Submission Report",
    element: ReportSubmission,
    permission: "reportView"
  }
];
