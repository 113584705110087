import { AxiosPatch, AxiosPost } from "../axios/Axios";

const prefix = "/notification";

export const getAllNotification = (data) => {
  return AxiosPost(`${prefix}/list`, data);
};

export const notificationMarkRead = (data) => {
  return AxiosPatch(`${prefix}/mark-read`, data);
};
