import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React from "react";
import { logoLight } from "../../constants/images";
import { useNavigate } from "react-router";
import { sidebarRoutes } from "../../router/routes/SidebarRoutes";
import { usePermission } from "../../contexts/permissionProvider";
import { useState } from "react";
import { iconUpArrow, iconDownArrow } from "../../constants/images";

const SideMenu = ({ mobileOpen, handleDrawerToggle, dynamicWidth }) => {
  const navigate = useNavigate();
  const { userPermission } = usePermission();
  const [indexData, setIndexData] = useState(null);

  // const [screenSize, getDimension] = useState({
  //   dynamicWidth: window.innerWidth,
  //   dynamicHeight: window.innerHeight
  // });
  // const setDimension = () => {
  //   getDimension({
  //     dynamicWidth: window.innerWidth,
  //     dynamicHeight: window.innerHeight
  //   })
  // }

  // useEffect(() => {
  //   window.addEventListener('resize', setDimension);

  //   return (() => {
  //     window.removeEventListener('resize', setDimension);
  //   })
  // }, [screenSize])

  // const dynamicWidth = screenSize.dynamicWidth >= 1400 ?
  //   drawerWidth : screenSize.dynamicWidth >= 1200 ?
  //     240 : screenSize.dynamicWidth >= 992 ?
  //       200 : screenSize.dynamicWidth >= 768 ?
  //         180 : 180

  const drawer = (
    <div className="mt-5">
      {/* <Toolbar />
      <Divider /> */}
      <List style={{paddingTop: "0"}}>
        {userPermission &&
          sidebarRoutes.map((item, index) => {
            return item.permission ? (
              userPermission[item.permission] && (
                <React.Fragment key={index}>
                  <ListItem key={index} disablePadding>
                    <ListItemButton style={{padding: "8px 24px"}}
                      onClick={
                        item.subNav && item.subNav.length > 0
                          ? () =>
                              setIndexData(index === indexData ? null : index)
                          : () => navigate(item.path)
                      }
                    >
                      <ListItemIcon style={{ minWidth: "24px", marginRight: "10px" }}>
                        <img
                          className="menu-icon"
                          src={item.icon}
                          alt={item.title}
                        />
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                      {item.subNav && item.subNav.length > 0 ? (
                        <img
                          src={
                            index === indexData ? iconUpArrow : iconDownArrow
                          }
                          alt="Up Down Arrow"
                        />
                      ) : (
                        ""
                      )}
                    </ListItemButton>
                  </ListItem>
                  <Collapse
                    in={index === indexData}
                    timeout="auto"
                    unmountOnExit
                  >
                    {item.subNav &&
                      item.subNav.length > 0 &&
                      item.subNav.map((subMenu, ind) => (
                        <List component="div" disablePadding key={ind}>
                          <ListItemButton style={{padding: "8px 58px"}}
                            onClick={() => navigate(subMenu.path)}
                          >
                            <ListItemText primary={subMenu.title} />
                          </ListItemButton>
                        </List>
                      ))}
                  </Collapse>
                </React.Fragment>
              )
            ) : (
              <React.Fragment key={index}>
                <ListItem key={index} disablePadding>
                  <ListItemButton style={{padding: "8px 24px"}}
                    onClick={
                      item.subNav && item.subNav.length > 0
                        ? () => setIndexData(index === indexData ? null : index)
                        : () => navigate(item.path)
                    }
                  >
                    <ListItemIcon style={{ minWidth: "24px", marginRight: "10px" }}>
                      <img
                        className="menu-icon"
                        src={item.icon}
                        alt={item.title}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                    {item.subNav && item.subNav.length > 0 ? (
                      <img
                        src={index === indexData ? iconUpArrow : iconDownArrow}
                        alt="Up Down Arrow"
                      />
                    ) : (
                      ""
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse in={index === indexData} timeout="auto" unmountOnExit>
                  {item.subNav &&
                    item.subNav.length > 0 &&
                    item.subNav.map((subMenu, ind) => (
                      <List component="div" disablePadding key={ind}>
                        <ListItemButton onClick={() => navigate(subMenu.path)}>
                          <ListItemText primary={subMenu.title} />
                        </ListItemButton>
                      </List>
                    ))}
                </Collapse>
              </React.Fragment>
            );
          })}
      </List>
      {/* <Divider /> */}
    </div>
  );

  return (
    <Box
      component="nav"
      // sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        // container={container}
        // className='dark-bg-color'
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: dynamicWidth,
            backgroundColor: "#10B3BA",
            color: "#fff",
            margin: "20px",
            borderRadius: "16px",
          },
        }}
      >
        <img className="drawer-logo" src={logoLight} alt={""} />
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        // className='dark-bg-color'
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: dynamicWidth,
            backgroundColor: "#10B3BA",
            color: "#fff",
            margin: "20px",
            borderRadius: "16px",
            border: "3px solid rgb(255 255 255 / 80%)",
            boxShadow: "0px 2px 18px 0px rgba(0, 0, 0, 0.1)",
          },
        }}
        // style={{backgroundColor:"#000"}}
        open
      >
        <img
          // style={{ marginLeft: 24, marginTop: 24 }}
          className="drawer-logo"
          src={logoLight}
          //   width={180}
          alt={""}
        />
        {drawer}
      </Drawer>
    </Box>
  );
};

export default SideMenu;
