import React, { useRef } from "react";
import { Button } from "@mui/material";
import "./userHeaderProfile.css";
import { usePermission } from "../../contexts/permissionProvider";

const UserProfile = ({ handleClick, id }) => {
  const { userProfile } = usePermission();

  const buttonRef = useRef(null);

  const handleClick1 = () => {
    buttonRef.current.click();
  };

  return (
    <div className="user-wrapper" onClick={() => handleClick1()}>
      <Button
        ref={buttonRef}
        style={{ display: "block", position: "absolute", opacity: 0 }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        Open Popover
      </Button>

      <img
        style={{ width: 32, height: 32, borderRadius: "50%" }}
        crossOrigin="anonymous"
        src={userProfile ? userProfile.profile : ""}
        alt=""
      />

      <div className="ms-2">
        <h6 className="user-name">
          {userProfile ? userProfile.displayName : "Name"}
        </h6>
        <h6 className="user-type">
          {userProfile ? userProfile.role.name : "Role"}
        </h6>
      </div>
    </div>
  );
};

export default UserProfile;
