import React from "react";
import * as ReactDOMClient from "react-dom/client";
import "./index.css";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./redux/store";
import { AuthProvider } from "./contexts/authProvider";
import { SplashScreenProvider } from "./components/layout/SplashScreen";
import "bootstrap/dist/css/bootstrap.min.css";
import setupAxios from "./axios/Axios";
import { PermissionProvider } from "./contexts/permissionProvider";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
setupAxios(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SplashScreenProvider>
        <PersistGate persistor={persistor}>
          <AuthProvider>
            <PermissionProvider>
              {/* <React.Suspense fallback={<LayoutSplashScreen />}> */}
              <App />
              {/* </React.Suspense> */}
            </PermissionProvider>
          </AuthProvider>
        </PersistGate>
      </SplashScreenProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
